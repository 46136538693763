<template>
  <v-row>
    <v-col>
      <v-alert type="success" dismissible close-text="Close Alert">
        Last imported invoice date was {{latestXeroUpdate}}, Go to Xero -> Invoices to refresh Xero data if necessary
      </v-alert>
      <v-tabs align-with-title>
        <v-tab to="/admin/business/reports/sales/products">By Product</v-tab>
        <v-tab to="/admin/business/reports/sales/customers">By Customer</v-tab>
        <v-tab to="/admin/business/reports/sales/artists">By Artist</v-tab>
        <v-tab to="/admin/business/reports/sales/artworks">By Artworks</v-tab>
        <v-tab to="/admin/business/reports/sales/regions">By Region</v-tab>
      </v-tabs>
      <router-view  />
    </v-col>
  </v-row>
</template>

<script>
import ReportApi from '@/api/admin/report'
import moment from 'moment'
export default {
  data () {
    return {
      latestXeroUpdate: null
    }
  },
  async created () {
    ReportApi.getLatestXeroUpdate().then((results) => {
      this.latestXeroUpdate = moment(results.data.invoiceDate).format('D MMMM YYYY')
    })
  }
}
</script>
